import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { Field } from 'formik'

const TextInput = ({
  disabled,
  id,
  label,
  inputProps,
  value,
  onChange,
  margin,
  variant,
  className,
}) => (
  <StyledTextField
    className={className}
    disabled={disabled}
    id={id}
    label={label}
    inputProps={inputProps}
    value={value}
    onChange={onChange}
    margin={margin}
    variant={variant}
  />
)

const StyledTextField = styled(TextField)`
  && {
    margin-left: 5px;
    margin-right: 5px;
  }
`

const TextInput2 = ({
  id,
  label,
  className,
  onChange,
  onBlur,
  error,
  type,
  defaultValue,
  required
}) => (
  <Wrapper className={className}>
    <StyledLabel required={required} htmlFor={id}>{label}</StyledLabel>
    <StyledInput
      type={type || 'text'}
      className="browser-default"
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      defaultValue={defaultValue}
    ></StyledInput>
    <Error>{error || null}</Error>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 0.5em;
  display: flex;
  flex-direction: column;
`
const StyledLabel = styled.label`
  font-size: 1em;
  margin: 0.5em 0;
  color: ${props => props.theme.colours.primary};
  ${props => props.required ? '&:after {content: " *"; color: red;}' : ''}
`
const StyledInput = styled.input`
  && {
    padding: 1em;
    height: 3em;
    border-radius: ${props => props.theme.blockBorderRadius};
    background-color: ${props => props.theme.colours.background};
    border: none;
    width: inherit;
    :focus {
      box-shadow: none;
      outline: none;
      /* border: 2px solid ${props => props.theme.colours.secondary}; */
      box-shadow: 0 0 0 2pt ${props => props.theme.colours.secondary};
    }
  }
`

const Error = styled.span`
  color: ${props => props.theme.colours.error};
`

export { TextInput2 as Input }
