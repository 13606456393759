import { createContext, useState } from 'react'

export const SelectedDeviceNotificationContext = createContext([[], () => {}])
SelectedDeviceNotificationContext.displayName =
  'SelectedDeviceNotificationContext'

export const SelectedDeviceNotificationProvider = ({ children }) => {
  const [selectedDeviceNotification, setSelectedDeviceNotification] = useState(
    null
  )

  return (
    <SelectedDeviceNotificationContext.Provider
      value={[selectedDeviceNotification, setSelectedDeviceNotification]}
    >
      {children}
    </SelectedDeviceNotificationContext.Provider>
  )
}
