import { useState, useEffect, useContext } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import useAuth from './components/hooks/useAuth'
import useUrlQueryParams from './components/hooks/useUrlQueryParams'
import { Panel } from './components/Panel'
import { Input } from './components/inputs/Form'
import Button from './rentalManagerViews/rentalManagerComponents/button'

export default function Login() {
  const history = useHistory()
  const query = useUrlQueryParams()
  const { login, loggedIn, user } = useAuth()

  const [apiErrors, setApiErrors] = useState({})

  useEffect(() => {
    const isLoggedIn = loggedIn()
    if (isLoggedIn) {
      history.replace(query.get('dest') || '/')
    }
  }, [history, loggedIn, query, user])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email()
        .required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: ({ email, password }) => {
      login(email, password)
        .then(res => {
          history.replace(query.get('dest') || '/')
        })
        .catch(err => {
          const errors = {}
          err.errors?.forEach(err => {
            errors[err.param] = err.msg
          })
          setApiErrors(errors)
          console.dir(err)
        })
    },
  })

  return (
    <div>
      <Background />
      <Grid>
        <StyledPanel>
          <Header>
            <Title>Login</Title>
          </Header>

          <StyledForm onSubmit={formik.handleSubmit}>
            <InputFullWidth
              id="email"
              label="email"
              inputProps={{ className: 'browser-default' }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.errors.email && formik.touched.email) ||
                  apiErrors?.email
                  ? apiErrors?.email || formik.errors.email
                  : null
              }
            ></InputFullWidth>
            <InputFullWidth
              type="password"
              id="password"
              label="password"
              inputProps={{ className: 'browser-default' }}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.errors.password && formik.touched.password) ||
                  apiErrors?.password
                  ? apiErrors?.password || formik.errors.password
                  : null
              }
            ></InputFullWidth>

            <FormFooter>
              <StyledButton type="submit">Login</StyledButton>
              <NavLink to="/register">Not a member? Register here</NavLink>
              <NavLink to="/forgotpass">Forgot Password?</NavLink>

            </FormFooter>
          </StyledForm>
        </StyledPanel>
      </Grid>
    </div>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Title = styled.h4`
  text-align: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`

const StyledPanel = styled(Panel)`
  margin: 10vh 0;
  grid-column: span 6;
  @media ${props => props.theme.device.mobileL} {
    grid-column: 2 / span 4;
  }
  @media ${props => props.theme.device.tablet} {
    padding: 2em;
  }
  @media ${props => props.theme.device.laptopL} {
    grid-column: 3 / span 2;
  }
`
const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2em 0em;
`

const FormFooter = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3em;
`
const StyledButton = styled(Button)`
  width: clamp(1rem, 80%, 25rem);
`

const InputFullWidth = styled(Input)`
  grid-column: span 2;
`

const Background = styled.div`
  background: rgb(10,49,116);
  background: linear-gradient(58deg, rgba(10,49,116,1) 0%, rgba(22,138,196,1) 35%, rgba(88,177,200,1) 100%);
  position: fixed;
  display: block;
  z-index: -1;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
`
