import { createContext, useState } from 'react'

export const AccountsContext = createContext([[], () => {}])
AccountsContext.displayName = 'AccountsContext'

export const AccountsProvider = ({ children }) => {
  const [accounts, setAccounts] = useState([])

  return (
    <AccountsContext.Provider value={[accounts, setAccounts]}>
      {children}
    </AccountsContext.Provider>
  )
}
