import { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import { Panel } from './components/Panel'
import useAuth from './components/hooks/useAuth'
import { Input } from './components/inputs/Form'
import Button from './rentalManagerViews/rentalManagerComponents/button'

export default function SignUp() {
  const history = useHistory()
  const { loggedIn, signUp, signUpStatus, signUpError } = useAuth()

  useEffect(() => {
    if (signUpStatus === 'success' || loggedIn()) {
      history.replace('/')
    }
  }, [history, loggedIn, signUpStatus])

  const [apiErrors, setApiErrors] = useState({})

  useEffect(() => {
    if (signUpError) {
      const errors = {}
      signUpError.errors?.forEach(err => {
        errors[err.param] = err.msg
      })
      setApiErrors(errors)
    }
  }, [signUpError])

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      email: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .optional()
        .matches(/^[0-9A-Za-z_ ]+$/, 'Cannot contain special characters'),
      lastname: Yup.string()
        .optional()
        .matches(/^[0-9A-Za-z_ ]+$/, 'Cannot contain special characters'),
      email: Yup.string().email(),
      password: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .required('Required'),

    }),
    onSubmit: ({ firstname, lastname, username, password, mobile, email }) => {
      signUp({
        firstname,
        lastname,
        password,
        email,
      })
    },
  })

  return (
    <div>
      <Background />
      <Grid>
        <StyledPanel>
          <Header>
            <Title>Register</Title>
          </Header>

          <StyledForm onSubmit={formik.handleSubmit}>
            <InputHalfWidth
              id="firstname"
              label="firstname"
              inputProps={{ className: 'browser-default' }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.errors.firstname && formik.touched.firstname) ||
                  apiErrors?.firstname
                  ? apiErrors?.firstname || formik.errors.firstname
                  : null
              }
            ></InputHalfWidth>
            <InputHalfWidth
              id="lastname"
              label="lastname"
              inputProps={{ className: 'browser-default' }}
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.errors.lastname && formik.touched.lastname) ||
                  apiErrors?.lastname
                  ? apiErrors?.lastname || formik.errors.lastname
                  : null
              }
            ></InputHalfWidth>
            <InputFullWidth
              required
              type="email"
              id="email"
              label="email"
              inputProps={{ className: 'browser-default' }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.errors.email && formik.touched.email) ||
                  apiErrors?.email
                  ? apiErrors?.email || formik.errors.email
                  : null
              }
            ></InputFullWidth>
            <InputFullWidth
              required
              type="password"
              id="password"
              label="password"
              inputProps={{ className: 'browser-default' }}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.errors.password && formik.touched.password) ||
                  apiErrors?.password
                  ? apiErrors?.password || formik.errors.password
                  : null
              }
            ></InputFullWidth>
            <FormFooter>
              <StyledButton type="submit">Register</StyledButton>
              <NavLink to="/login">Already a member? Login here</NavLink>
            </FormFooter>
          </StyledForm>
        </StyledPanel>
      </Grid>
    </div>
  )
}

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Title = styled.h4`
  text-align: center;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`

const StyledPanel = styled(Panel)`
  margin: 10vh 0;
  grid-column: span 6;
  @media ${props => props.theme.device.mobileL} {
    grid-column: 2 / span 4;
  }
  @media ${props => props.theme.device.tablet} {
    padding: 2em;
  }
  @media ${props => props.theme.device.laptopL} {
    grid-column: 3 / span 2;
  }
`
const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2em 0em;
`

const FormFooter = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3em;
`
const StyledButton = styled(Button)`
  width: clamp(1rem, 80%, 25rem);
`
const InputFullWidth = styled(Input)`
  grid-column: span 2;
`
const InputHalfWidth = styled(Input)`
  grid-column: span 2;

  @media ${props => props.theme.device.tablet} {
    grid-column: span 1;
  }
`
const Background = styled.div`
  background: rgb(10,49,116);
  background: linear-gradient(58deg, rgba(10,49,116,1) 0%, rgba(22,138,196,1) 35%, rgba(88,177,200,1) 100%);
  position: fixed;
  display: block;
  z-index: -1;
  left: -5px;
  right: -5px;
  top: -5px;
  bottom: -5px;
`
