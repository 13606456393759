import styled, { ThemeProvider } from 'styled-components'

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

const Theme = {
  colours: {
    primary: '#168AC4',
    secondary: '#126F9E',
    highlight: '#bbe9ff',
    text: '#353c47',
    shadow: '#353c47',
    accentColour: '#C20114',
    background: '#f8f9fc',
    foreground: '#ffffff',
    border: '#e3e6f0',
    sideMenuTextLight: 'rgba(255,255,255,0.6)',
    sideMenuTextLightActive: 'rgba(255,255,255,0.9)',
    menuActive: 'rgba(255,255,255,0.6)',
    delete: '#eb4034',
    error: '#eb4034',
    success: '#91d84e',
    info: '#ffd344',
  },
  device: {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
  },
  blockBorderRadius: '8px',
  panelPadding: '10px',
}

//  dark #263644
//  very dark #202531

export default function ThemeProviderWithTheme({ children }) {
  return <ThemeProvider theme={Theme}>{children}</ThemeProvider>
}
