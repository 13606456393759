import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { SocketProvider } from './components/SocketContext'
import { SelectedAccountProvider } from './components/contexts/SelectedAccountContext'
import { SelectedDeviceNotificationProvider } from './components/contexts/SelectedDeviceNotificationContext'
import { AuthProvider } from './components/contexts/AuthContext'
import { AccountsProvider } from './components/contexts/AccountsContext'
import ThemeProvider from './Theme'
import Register from './register'
import Login from './login'
import ForgotPass from './forgotPass'
import ResetPass from './resetPass'
import LoadingPage from './rentalManagerViews/LoadingPage'

const RentalManager = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "RentalManager" */ './rentalManagerViews/RentalManager'
  )
)
const queryClient = new QueryClient()

const findSubdomain = () => {
  if (window.location.hostname.split('.')[0] === 'test') {
    return window.location.hostname.split('.')[1]
  }
  return window.location.hostname.split('.')[0]
}
const Client = () => {
  const subdomain = findSubdomain()
  switch (subdomain) {
    case 'fleets':
      return (
        <ThemeProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <div>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/forgotpass" component={ForgotPass} />
                    <Route exact path="/resetpassword" component={ResetPass} />
                    <Route
                      path="/"
                      render={() => (
                        <SelectedDeviceNotificationProvider>
                          <AccountsProvider>
                            <SelectedAccountProvider>
                              <SocketProvider>
                                <Suspense fallback={<LoadingPage />}>
                                  <RentalManager />
                                </Suspense>
                              </SocketProvider>
                            </SelectedAccountProvider>
                          </AccountsProvider>
                        </SelectedDeviceNotificationProvider>
                      )}
                    />
                    <Route render={() => <p> Not Found </p>} />
                  </Switch>
                </div>
              </AuthProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </ThemeProvider>
      )
    default:
      return (
        <ThemeProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <div>
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/forgotpass" component={ForgotPass} />
                    <Route exact path="/resetpassword" component={ResetPass} />

                    <Route
                      path="/"
                      render={() => (
                        <SelectedDeviceNotificationProvider>
                          <AccountsProvider>
                            <SelectedAccountProvider>
                              <SocketProvider>
                                <Suspense fallback={<LoadingPage />}>
                                  <RentalManager />
                                </Suspense>
                              </SocketProvider>
                            </SelectedAccountProvider>
                          </AccountsProvider>
                        </SelectedDeviceNotificationProvider>
                      )}
                    />
                    <Route render={() => <p> Not Found </p>} />
                  </Switch>
                </div>
              </AuthProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </ThemeProvider>
      )
  }
}

export default Client
