import { io } from 'socket.io-client'
import { createContext, useEffect, useState } from 'react'
import useAuth from './hooks/useAuth'

export const SocketContext = createContext({})

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState()
  const [socketAuth, setSocketAuth] = useState(false)
  const [s3socket, setS3Socket] = useState()
  const [s3SocketAuth, setS3SocketAuth] = useState(false)

  useEffect(() => {
    setSocket(
      io(`${import.meta.env.VITE_API_HOST}/ui-socket`, {
        transports: ['websocket'],
      })
    )
    setS3Socket(
      io(`${import.meta.env.VITE_API_HOST}/s3-socket`, {
        transports: ['websocket'],
      })
    )
  }, [])

  const { idToken } = useAuth()
  const value = {
    socket,
    socketAuth,
    s3socket,
    s3SocketAuth,
  }
  useEffect(() => {
    if (socket) {
      // Initialize socket
      socket.on('authenticated', (isAuthenticated) => {
        setSocketAuth(isAuthenticated)
      })
      // Stop listening on socket on close
      return () => {
        socket.off('authenticated')
      }
    }
  }, [socket])
  useEffect(() => {
    if (s3socket) {
      // Initialize socket
      s3socket.on('authenticated', (isAuthenticated) => {
        setS3SocketAuth(isAuthenticated)
      })

      // Stop listening on socket on close
      return () => {
        s3socket.off('authenticated')
      }
    }
  }, [s3socket])

  useEffect(() => {
    if (idToken && socket && s3socket) {
      socket.emit('authenticate', idToken)
      s3socket.emit('authenticate', idToken)
    }
  }, [idToken, s3socket, socket])
  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  )
}
