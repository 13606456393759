import React from 'react'
import { render } from 'react-dom'
import * as Sentry from '@sentry/react'
// import jquery from 'jquery'
// import '../public/include/js/materialize.min.js'
import 'materialize-css'
// import 'materialize-css/dist/css/materialize.min.css'

// Import icon font
import 'material-design-icons/iconfont/material-icons.css'
import Client from './views/client'
// import App from './views/app'

// window.$ = window.jQuery = window.jquery = jquery
if (import.meta.env.VITE_SENTRY_ENVIRONMENT === 'Production' || import.meta.env.VITE_SENTRY_ENVIRONMENT === 'Testing') {
    Sentry.init({ dsn: import.meta.env.VITE_SENTRY_BROWSER_DSN, environment: import.meta.env.VITE_SENTRY_ENVIRONMENT })
}

window.React = React
render(<Client />, document.getElementById('react-container'))
