import { createContext, useState, useContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import { AuthContext } from './AuthContext'

export const SelectedAccountContext = createContext([{}, () => { }])
SelectedAccountContext.displayName = 'SelectedAccountContext'

export const SelectedAccountProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [selectedAccount, setSelectedAccount] = useState()

  const [selectedAccountId, setSelectedAccountId] = useLocalStorage(
    `selectedAccountId-${user?.email}`,
    ''
  )

  return (
    <SelectedAccountContext.Provider
      value={[
        selectedAccount,
        setSelectedAccount,
        selectedAccountId,
        setSelectedAccountId,
      ]}
    >
      {children}
    </SelectedAccountContext.Provider>
  )
}
