import { createContext, useState, useEffect, useCallback, useMemo } from 'react'
import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
} from '@aws-sdk/client-cognito-identity'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from 'react-query'
import decode from 'jwt-decode'

const domain = import.meta.env.VITE_API_HOST

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token)
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [idToken, setIdToken] = useState(null)
  const [awsToken, setAwsToken] = useState(null)
  const [awsIdentityId, setAwsIdentityId] = useState(null)
  const [awsCredentials, setAwsCredentials] = useState(null)
  const [validRefreshToken, setValidRefreshToken] = useState(true)

  useEffect(() => {
    const load = async () => {
      if (awsToken && awsIdentityId) {
        const client = new CognitoIdentityClient({ region: 'ap-southeast-2' })
        const command = new GetCredentialsForIdentityCommand({
          IdentityId: awsIdentityId,
          Logins: {
            'cognito-identity.amazonaws.com': awsToken,
          },
        })
        const data = await client.send(command)
        setAwsCredentials(data.Credentials)
      }
    }
    load()
  }, [awsToken, awsIdentityId])

  // Start refresh token

  const { data: tokenData, status } = useQuery(
    'refreshToken',
    async () => {
      const r = await fetch(`${domain}/auth/refresh`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
      })
      const resStatus = r.status
      const json = r.json()
      if (resStatus >= 200 && resStatus < 300) {
        return json
      }
      throw json
    },
    {
      staleTime: 1000 * 60,
      // eslint-disable-next-line no-undef
      refetchInterval: import.meta.env.VITE_JWT_REFRESH_INTERVAL,
      retry: false,
      enabled: !!validRefreshToken,
    }
  )

  useEffect(() => {
    Sentry.setUser(user)
  }, [user])

  useEffect(() => {
    setValidRefreshToken(status !== 'error')
  }, [status])

  useEffect(() => {
    if (tokenData) {
      setIdToken(tokenData.token)
      setAwsToken(tokenData.AwsToken)
      setAwsIdentityId(tokenData.AwsIdentityId)
    }
  }, [tokenData])

  // End refresh token

  // Update User context when the idToken changes
  useEffect(() => {
    let u = null
    if (!!idToken && !isTokenExpired(idToken)) {
      try {
        u = decode(idToken)
      } catch (error) {
        console.error(error)
      }
    }
    setUser(u)
  }, [idToken])

  const loggedIn = useCallback(() => {
    // Checks if there is a saved token and it's still valid
    const bool = !!idToken && !isTokenExpired(idToken) && !!user // handwaiving here
    return bool
  }, [idToken, user])

  const login = useCallback(async (email, password) => {
    // Get a token from api server using fetch api
    const res = await fetch(`${domain}/auth/login`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        email,
        password,
      }),
    })
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json()

      setIdToken(json.token)
      setAwsToken(json.AwsToken)
      setAwsIdentityId(json.AwsIdentityId)

      setValidRefreshToken(true)

      return json
    }
    const err = await res.json()
    throw err
  }, [])

  const forgotPass = useCallback(async (email) => {
    // Get a token from api server using fetch api
    const res = await fetch(`${domain}/auth/passwordreset`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        email,
      }),
    })
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json()

      setIdToken(json.token)
      setAwsToken(json.AwsToken)
      setAwsIdentityId(json.AwsIdentityId)
      setValidRefreshToken(true)

      return json
    }
    const err = await res.json()
    throw err
  }, [])

  const resetPass = useCallback(async (password, token) => {
    // Get a token from api server using fetch api
    const res = await fetch(`${domain}/auth/resetpassword`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        password,
        token,
      }),
    })
    if (res.status >= 200 && res.status < 300) {
      const json = await res.json()

      setIdToken(json.token)
      setAwsToken(json.AwsToken)
      setAwsIdentityId(json.AwsIdentityId)
      setValidRefreshToken(true)

      return json
    }
    const err = await res.json()
    throw err
  }, [])

  const logout = useCallback(() => {
    fetch(`${domain}/auth/logout`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    }).then((res) => {
      // Clear tokens
      setIdToken(null)
      setAwsToken(null)
      setAwsIdentityId(null)
    })
  }, [])

  const {
    mutate: signUp,
    status: signUpStatus,
    error: signUpError,
  } = useMutation(
    async (user) => {
      const res = await fetch(`${domain}/api/users/register`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          ...user,
        }),
      })
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json()
        return json
      }
      const json = await res.json()
      throw json
    },
    {
      onSuccess: (json) => {
        setIdToken(json.token)
        setAwsToken(json.AwsToken)
        setAwsIdentityId(json.AwsIdentityId)
        setValidRefreshToken(true)
      },
    }
  )

  const authFetch = useCallback(
    async (url, options) => {
      //  performs api calls sending the required authentication headers
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

      // Setting Authorization header
      // Authorization: Bearer : xxxxxx.xxxxxx.xxxxx
      if (loggedIn()) {
        headers.Authorization = `Bearer ${idToken}`
      } else {
        console.error('not logged in')
      }

      const res = await fetch(url, {
        headers,
        ...options,
      })

      if (res.status >= 200 && res.status < 300) {
        return res.json()
      }
      const error = await res.json()
      throw error
    },
    [idToken, loggedIn]
  )

  const checkRoles = useCallback(
    (role) => {
      const decoded = decode(idToken)
      if (decoded.hasOwnProperty('roles')) {
        return decoded.roles.includes(role)
      }
      return false
    },
    [idToken]
  )

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        awsCredentials,
        loggedIn,
        idToken,
        forgotPass,
        resetPass,
        login,
        logout,
        signUp,
        signUpStatus,
        signUpError,
        authFetch,
        checkRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element,
}
