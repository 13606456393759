import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  background: ${props => {
    if (props.disabled) {
      return '#ddd'
    }
    switch (props.colour) {
      case 'delete':
        return props.theme.colours.delete
      case 'background':
        return props.theme.colours.background
      default:
        return props.theme.colours.primary
    }
  }};
  color: ${props => {
    if (props.disabled) {
      return props.theme.colours.foreground
    }
    switch (props.colour) {
      case 'delete':
        return props.theme.colours.foreground
      case 'background':
        return props.theme.colours.primary
      default:
        return props.theme.colours.foreground
    }
  }};
  border: 1px solid ${props => props.theme.colours.border};
  border-radius: ${props => props.theme.blockBorderRadius};
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;
  margin: 0.2rem 0.5rem;
  min-height: 3.5rem;
  height: fit-content;
  width: fit-content;
  min-width: 60px;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease, filter 250ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover,
  &:focus {
    background: ${props => {
    if (props.disabled) {
      return '#ddd'
    }
    switch (props.colour) {
      case 'delete':
        return props.theme.colours.delete
      case 'background':
        return props.theme.colours.menuActive
      default:
        return props.theme.colours.primary
    }
  }};
    filter: brightness(80%);
  }

  &:active {
    transform: scale(0.97);
  }
`
export default StyledButton
