import { useContext, useMemo } from 'react'
import { AuthContext } from '../contexts/AuthContext'

// eslint-disable-next-line no-undef
const domain = import.meta.env.VITE_API_HOST

export default function useAuth() {
  const {
    user,
    awsCredentials,
    loggedIn,
    idToken,
    forgotPass,
    resetPass,
    login,
    logout,
    signUp,
    signUpStatus,
    signUpError,
    authFetch,
    checkRoles,
  } = useContext(AuthContext)

  return useMemo(
    () => ({
      user,
      domain,
      authFetch,
      signUp,
      signUpStatus,
      signUpError,
      forgotPass,
      resetPass,
      login,
      loggedIn,
      idToken,
      checkRoles,
      awsCredentials,
      logout,
    }),
    [
      user,
      authFetch,
      signUp,
      signUpStatus,
      signUpError,
      forgotPass,
      resetPass,
      login,
      loggedIn,
      idToken,
      checkRoles,
      awsCredentials,
      logout,
    ]
  )
}
