import React from 'react'
import styled from 'styled-components'

export default function LoadingPage() {
  return <Wrapper>Loading</Wrapper>
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
