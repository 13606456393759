import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import CircularLoader from './feedback/CircularLoader'

const Panel = ({ children, className, onClick, loading, borderleft }) => {
  const props = useSpring({
    opacity: 1,
    position: 'relative',
    top: '0px',
    from: { opacity: 0, position: 'relative', top: '30px' },
  })
  return (
    <Wrapper
      style={props}
      onClick={onClick}
      className={className}
      borderleft={borderleft}
    >
      {loading && (
        <Overlay style={props}>
          <CircularLoader />
        </Overlay>
      )}
      {children}
    </Wrapper>
  )
}

const PanelHeader = ({ children }) => <StyledHeader>{children}</StyledHeader>

const StyledHeader = styled.div`
  width: 100%;
  height: 50px;
  color: ${props => props.theme.colours.primary};
  background-color: ${props => props.theme.colours.background};
  border-bottom: 1px solid ${props => props.theme.colours.border};
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Wrapper = styled(animated.div)`
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border-radius: ${props => props.theme.blockBorderRadius};
  overflow: auto;
  border: 1px solid ${props => props.theme.colours.border};
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.colours.foreground};
  border-left: ${props => {
    switch (props.borderleft) {
      case 'green':
        return `0.25rem solid ${props.theme.colours.success};`
      case 'red':
        return `0.25rem solid ${props.theme.colours.error};`
      default:
        return 'none'
    }
  }};
`

const Overlay = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`
export { Panel, PanelHeader }
